
import { defineComponent } from "vue";

export default defineComponent({
  name: "profile",
  components: {},
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
    };
  },
});
